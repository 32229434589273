(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/expanded-systems/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/sport/expanded-systems/assets/javascripts/utils.js');
"use strict";


const OUTCOME_VARIANTS = ['1', 'X', '1X', '2', '12', 'X2', '1X2'];
const convertBitmapRowsToReadable = bitmapRows => {
  if (!Array.isArray(bitmapRows)) {
    throw new Error('Parameter bitmapRows is not an array');
  }
  const readableRows = [];
  for (const board of bitmapRows) {
    const row = [];
    for (const outcome of board) {
      const char = outcome.toLowerCase();
      if (isNaN(parseInt(char, 10)) || char === 'd' || char === '0') {
        continue;
      }
      row.push(OUTCOME_VARIANTS[char - 1]);
    }
    readableRows.push(row);
  }
  return readableRows;
};
const convert1X2ToBitmapRows = rows => {
  const boards = [];
  for (const row of rows) {
    let board = '';
    for (const outcome of row) {
      board += OUTCOME_VARIANTS.indexOf(outcome) + 1;
    }
    boards.push(board);
  }
  return boards;
};
setGlobal('svs.components.sport.expandedSystems.utils', {
  convertBitmapRowsToReadable,
  convert1X2ToBitmapRows
});

 })(window);