(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/expanded-systems/assets/javascripts/expanded-systems.js') >= 0) return;  svs.modules.push('/components/sport/expanded-systems/assets/javascripts/expanded-systems.js');

'use strict';

const {
  SYSTEMS,
  getSystemKeyFromNumber
} = svs.components.sport.systems;
const {
  convertBitmapRowsToReadable
} = svs.components.sport.expandedSystems.utils;
let currentSystem;
let rowModel;
let viewEl;

const getExpandedRowsFromSingleRow = function (system, row, urows) {
  const fullHedgesIndices = []; 
  const halfHedgesIndices = []; 
  const singlesIndices = []; 
  const expandedRows = [];
  const rowLength = row.length;
  const marks = ['1', 'X', '2'];
  const halfMarks = {
    '1X': ['1', 'X'],
    12: ['1', '2'],
    X2: ['X', '2']
  };
  const uMarks = {
    1: ['1', 'X', '2'],
    X: ['X', '1', '2'],
    2: ['2', '1', 'X']
  };
  const uHalfMarks = {
    1: {
      '1X': ['1', 'X'],
      12: ['1', '2']
    },
    X: {
      '1X': ['X', '1'],
      X2: ['X', '2']
    },
    2: {
      12: ['2', '1'],
      X2: ['2', 'X']
    }
  };
  const systemRows = SYSTEMS[system];
  const isUSystem = systemRows.systemType === 'U'; 
  let currentFullIndex = null;
  let currentHalfIndex = null;
  if (typeof systemRows === 'undefined') {
    throw new Error("Failed to find a system with name: ".concat(system));
  }

  for (let i = 0; i < rowLength; ++i) {
    if (row[i].length === 3) {
      fullHedgesIndices.push(i);
    } else if (row[i].length === 1) {
      singlesIndices.push(i);
    } else {
      halfHedgesIndices.push(i);
    }
  }
  const systemRowsLength = systemRows.rows.length;
  const fullHedgesIndicesLength = fullHedgesIndices.length;
  const halfHedgesIndicesLength = halfHedgesIndices.length;
  for (let i = 0; i < systemRowsLength; ++i) {
    currentFullIndex = -1;
    currentHalfIndex = fullHedgesIndicesLength - 1;
    expandedRows.push([]);
    for (let k = 0; k < fullHedgesIndicesLength; ++k) {
      if (isUSystem) {
        expandedRows[expandedRows.length - 1][fullHedgesIndices[k]] = uMarks[urows[fullHedgesIndices[k]]][systemRows.rows[i][++currentFullIndex]];
      } else {
        expandedRows[expandedRows.length - 1][fullHedgesIndices[k]] = marks[systemRows.rows[i][++currentFullIndex]];
      }
    }
    for (let k = 0; k < halfHedgesIndicesLength; ++k) {
      if (isUSystem) {
        expandedRows[expandedRows.length - 1][halfHedgesIndices[k]] = uHalfMarks[urows[halfHedgesIndices[k]]][row[halfHedgesIndices[k]]][systemRows.rows[i][++currentHalfIndex]];
      } else {
        expandedRows[expandedRows.length - 1][halfHedgesIndices[k]] = halfMarks[row[halfHedgesIndices[k]]][systemRows.rows[i][++currentHalfIndex]];
      }
    }

    for (let k = 0; k < singlesIndices.length; ++k) {
      expandedRows[expandedRows.length - 1][singlesIndices[k]] = row[singlesIndices[k]];
    }
  }
  return expandedRows;
};

const getMathRows = function (row, currentMark, marks) {
  let combinedRows = [];

  if (marks.length === currentMark) {
    return [];
  }

  const currentMarkLength = marks[currentMark].marks.length;
  for (let i = 0; i < currentMarkLength; ++i) {
    row[marks[currentMark].position] = marks[currentMark].marks[i];

    if (currentMark === marks.length - 1) {
      combinedRows.push(JSON.parse(JSON.stringify(row)));
    }

    combinedRows = combinedRows.concat(getMathRows(row, currentMark + 1, marks));
  }
  return combinedRows;
};

const getExpandedRows = function (system, row, uRows) {
  if (typeof system !== 'string' && typeof system !== 'number') {
    throw new Error("First parameter must be of type string or number, ".concat(typeof system, " given"));
  }
  if (!Array.isArray(row)) {
    throw new Error("Second parameter must be array, ".concat(typeof row, " given"));
  }
  const marks = [];
  const currentRow = JSON.parse(JSON.stringify(row));
  const currentRowLength = currentRow.length;
  let rowsToProcess = [];
  let processedRows = [];
  let rowsToProcessLength = 0;
  for (let i = 0; i < currentRowLength; i++) {
    if (currentRow[i].indexOf('M') !== -1) {
      marks.push({
        position: i,
        marks: currentRow[i].substr(0, currentRow[i].length - 1)
      });
    }
  }
  if (marks.length) {
    rowsToProcess = getMathRows(currentRow, 0, marks);
  } else {
    rowsToProcess.push(currentRow);
  }
  rowsToProcessLength = rowsToProcess.length;
  for (let i = 0; i < rowsToProcessLength; ++i) {
    processedRows = processedRows.concat(getExpandedRowsFromSingleRow(typeof system === 'number' ? getSystemKeyFromNumber(system) : system, rowsToProcess[i], uRows));
  }
  return processedRows;
};
const addRowsToView = function (opt) {
  if (rowModel.rows.length) {
    let row = rowModel.rows.shift();
    while (row) {
      viewEl.append("<ul class=\"left expanded-rows-list\"><li>".concat(row.join('</li><li>'), "</li></ul>"));
      if (opt.timeRemaining() > 0) {
        row = rowModel.rows.shift();
      } else {
        row = null;
        window.requestIdleCallback(addRowsToView);
      }
    }
  }
};
const getExpandedRowsView = function (system, row, urows, isExpandedSystems) {
  if (!rowModel || !rowModel.length || currentSystem !== system) {
    currentSystem = system;
    if (isExpandedSystems) {
      rowModel = {
        rows: convertBitmapRowsToReadable(row)
      };
    } else {
      rowModel = {
        rows: getExpandedRows(system, row, urows)
      };
    }
    let base64Rows = '';
    const rowsLength = rowModel.rows.length;
    for (let i = 0; i < rowsLength; ++i) {
      base64Rows += rowModel.rows[i].join('\r\n');
      base64Rows += '\r\n';
    }
    rowModel.base64Rows = window.btoa(base64Rows);
  }
  const divTag = document.createElement('div');
  const innerDivTag = document.createElement('div');
  innerDivTag.className = 'expanded-rows-wrap';
  divTag.appendChild(innerDivTag);
  divTag.className = 'expanded-rows bg-white padding-top-1 padding-bottom-1 padding-left-2 padding-right-2 grid-row';
  viewEl = $(innerDivTag);
  if (isExpandedSystems) {
    viewEl.prepend('<div class="f-600 f-bold f-prominent padding-bottom-1">Dina rader</div>');
  }
  window.requestIdleCallback(addRowsToView);
  return divTag;
};
function splitBombenBoardDataToRows(boardData, rSysData) {
  const rowLength = boardData.length;
  const markIndicies = {};
  const propagateIndicies = rowIndex => {
    if (rowIndex === rowLength) {
      return;
    }
    markIndicies[rowIndex] += 1;
    if (markIndicies[rowIndex] === boardData[rowIndex].length) {
      markIndicies[rowIndex] = 0;
      propagateIndicies(rowIndex + 1);
    }
  };
  const totalRows = boardData.map(marks => marks.length).reduce((acc, numMarks) => acc * numMarks, 1);
  const nonReducedRows = [];
  for (let i = 0; i < rowLength; ++i) {
    markIndicies[i] = 0;
  }
  for (let i = 0; i < totalRows; ++i) {
    const row = [];
    for (let x = 0; x < rowLength; ++x) {
      const markIndex = markIndicies[x];
      row.push(boardData[x][markIndex]);
    }
    propagateIndicies(0);
    nonReducedRows.push(row);
  }
  if (!rSysData) {
    return nonReducedRows;
  }
  const reducedRows = nonReducedRows.filter(row => {
    for (let i = 0; i < row.length; i += 2) {
      const rSys = rSysData[i / 2];
      if (rSys.indexOf('HOME') === -1) {
        if (Number(row[i]) > Number(row[i + 1])) {
          return false;
        }
      }
      if (rSys.indexOf('DRAW') === -1) {
        if (row[i] === row[i + 1]) {
          return false;
        }
      }
      if (rSys.indexOf('AWAY') === -1) {
        if (Number(row[i]) < Number(row[i + 1])) {
          return false;
        }
      }
    }
    return true;
  });
  return reducedRows;
}
function splitBombenExpertenBoardDataToRows(boardData) {
  const rows = splitBombenBoardDataToRows(boardData);
  return rows.map(row => row.reduce((acc, value) => [...acc, value[0], value[1]], []));
}
const splitToRows = function (row) {
  if (!Array.isArray(row)) {
    row = [];
  }
  const marks = [];
  let rows = [];
  for (let i = 0; i < row.length; ++i) {
    if (row[i].length > 1) {
      marks.push({
        position: i,
        marks: row[i]
      });
    }
  }
  if (marks.length) {
    rows = getMathRows(row.slice(0), 0, marks);
  } else {
    rows = row;
  }
  return rows;
};
const getDownloadButton = function () {
  const aTag = document.createElement('a');
  aTag.className = 'btn btn-300 btn-default js-expanded-rows-dwlnld-btn';
  aTag.innerHTML = '<span class="btn-link-text">Ladda ned fil</span>';
  aTag.href = "data:application/octet-stream;charset:UTF-8;base64,".concat(rowModel.base64Rows);
  aTag.download = 'mina_rader.txt';
  return aTag;
};

const getDistribution = function (rows) {
  const distribution = [];
  let matchCount;
  if (rows.length > 0) {
    matchCount = rows[0].length;
  }
  for (let i = 0; i <= matchCount; ++i) {
    distribution.push({
      1: 0,
      X: 0,
      2: 0
    });
  }
  rows.forEach(row => {
    row.forEach((mark, matchIndex) => {
      if (mark.toUpperCase() === '1') {
        return ++distribution[matchIndex]['1'];
      }
      if (mark.toUpperCase() === 'X') {
        return ++distribution[matchIndex].X;
      }
      if (mark.toUpperCase() === '2') {
        return ++distribution[matchIndex]['2'];
      }
    });
  });
  distribution.forEach(dist => {
    dist['1'] = Math.round(dist['1'] / rows.length * 100);
    dist.X = Math.round(dist.X / rows.length * 100);
    dist['2'] = Math.round(dist['2'] / rows.length * 100);
  });
  return distribution;
};

const expandMSYS = function (boards) {
  boards = convertBitmapRowsToReadable(boards);
  const singleRows = [];
  for (let i = 0; i < boards.length; i++) {
    boards[i].forEach((mark, k) => {
      if (mark === '1X' || mark === 'X2' || mark === '12') {
        mark = mark.split('');
        boards[i][k] = mark[0];
        const additionalBoard = [];
        for (let j = 0; j < boards[i].length; j++) {
          if (j === k) {
            additionalBoard[j] = mark[1];
          } else {
            additionalBoard[j] = boards[i][j];
          }
        }
        boards.push(additionalBoard);
      }
      if (mark === '1X2') {
        mark = mark.split('');
        boards[i][k] = mark[0];
        const additionalBoardX = [];
        const additionalBoard2 = [];
        for (let j = 0; j < boards[i].length; j++) {
          if (j === k) {
            additionalBoardX[j] = mark[1];
            additionalBoard2[j] = mark[2];
          } else {
            additionalBoardX[j] = boards[i][j];
            additionalBoard2[j] = boards[i][j];
          }
        }
        boards.push(additionalBoardX);
        boards.push(additionalBoard2);
      }
    });
    singleRows.push(boards[i]);
  }
  return singleRows;
};
setGlobal('svs.components.sport.expandedSystems', {
  getExpandedRowsView,
  getDownloadButton,
  getExpandedRows,
  getDistribution,
  splitToRows,
  expandMSYS,
  SYSTEMS,
  getSystemKeyFromNumber,
  splitBombenExpertenBoardDataToRows,
  splitBombenBoardDataToRows
});

 })(window);